import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: 'catalog/:catalogName/:codeCatalog', component: () => import('@/pages/catalog/CatalogPage.vue'),
                name: 'catalog',
                props: (route) => {
                    return {
                        catalogName: route.params.catalogName,
                        codeCatalog: route.params.codeCatalog
                    }
                }
            },
            {
                path: 'product/:id', component: () => import('@/pages/product/ProductPage.vue'),
                name: 'product',
                props: (route) => {
                    return {
                        id: route.params.id
                    }
                }
            },
            {
                path: 'cart', component: () => import('@/pages/cart/CartPage.vue'),
                name: 'cart'
            },
            {
                path: '', component: () => {
                    window.location.href = 'http://revendo.online';
                }
            }
        ]
    },
    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        component: () => {
            window.location.href = 'http://revendo.online';
        }
    }
]

export default routes;
