import { Storage } from '@capacitor/storage';

class StorageService {

    async setItem(key: string, value: string) {
        await Storage.set({key: key, value: value});
    }

    async getItem(key: string) {
        const {value} = await Storage.get({key: key});
        return value;
    }

    async removeItem(key: string) {
        await Storage.remove({key: key});
    }

    async clear() {
        await Storage.clear();
    }

}

export default new StorageService();
