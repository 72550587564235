import {ApolloClient, HttpLink, InMemoryCache, split} from "@apollo/client/core"
import userService from "@/services/userService";
import {setContext} from "@apollo/client/link/context";
import {getMainDefinition} from "@apollo/client/utilities";
import {createClient} from "graphql-ws";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import environment from "@/enviroment";
import {createApp, h} from "vue";
import App from "@/App.vue";
import {createApolloProvider} from "@vue/apollo-option";


const httpLink = new HttpLink({
    uri: environment.HASURA_URL
})

const wsLink = new GraphQLWsLink(
    createClient({
        url: environment.HASURA_WS,
        connectionParams: async () => {
            const token = await userService?.getToken();
            return {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            }
        }
    })
);

const authMiddLink = setContext(async (req, {headers}) => {

    const token = await userService?.getToken();
    return {
        headers: {
            ...headers,
            'authorization': 'Bearer ' + token
        }
    }
})

interface Definintion {
    kind: string;
    operation?: string;
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({query}) => {
        const {kind, operation}: Definintion = getMainDefinition(query)
        return kind === 'OperationDefinition' &&
            operation === 'subscription'
    },
    wsLink,
    authMiddLink.concat(httpLink),
)


export const apolloClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    connectToDevTools: true
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})

const app = createApp({
    render: () => h(App),
})

app.use(apolloProvider)
