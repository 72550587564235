import {
    hasuraURL,
    hasuraWs,
    authUrl,
    identityProviderApiKey,
    identityProviderAuthDomain,
    storage_url,
    client_url
} from "./main";

class Environment {

    public HASURA_URL: string = (process.env.DEV) ?
        hasuraURL : 'https://gql.revendo.com.ar/v1/graphql';

    public HASURA_WS: string = (process.env.DEV) ?
        hasuraWs : 'wss://gql.revendo.com.ar/v1/graphql';

    public AUTH_URL: string = (process.env.DEV) ?
        authUrl : 'https://auth.revendo.com.ar';

    public IDENTITY_PROVIDER_API_KEY: string = (process.env.DEV) ?
        identityProviderApiKey : 'AIzaSyDfyCzqPGhB2xw3E5t5UudVDmf2l4r5M08';

    public IDENTITY_PROVIDER_AUTH_DOMAIN: string = (process.env.DEV) ?
        identityProviderAuthDomain : 'floube-2d559.firebaseapp.com';

    public STORAGE_URL: string = (process.env.DEV) ?
        storage_url : 'https://storage.revendo.com.ar';

    public CLIENT_URL: string = (process.env.DEV) ?
        client_url : 'https://app.revendo.com.ar'
}

export default new Environment();
