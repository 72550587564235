import {Configuration, AuthApi,} from '@codegen/floubeAuth-api';
import axios from '@codegen/floubeAuth-api/node_modules/axios';
import environment from "@/enviroment";

export let authApi: AuthApi;

function boot() {
    const configAxio = {};

    const configuration = new Configuration();
    configuration.basePath = environment.AUTH_URL;
    configuration.baseOptions = configAxio;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-plus-operands
    const axiosInstance = axios.create({baseURL: configuration.basePath})

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
    authApi = new AuthApi(configuration, undefined, axiosInstance);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    axiosInstance.interceptors.response.use(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        response => response,
        error => {
            console.error(error)
        }
    )
}

export default boot;
